import { http } from '@/utils/http';

export type AlltIdUserCode = string;
export type CreateAccountPasswordless = (
  userData: { firstName: string; lastName: string; email: string },
  newsletterSubscription: boolean,
) => Promise<{ alltIdUserCode: AlltIdUserCode; isNewUser: boolean }>;

export const createAccountPasswordless: CreateAccountPasswordless = async (userData, newsletterSubscription) => {
  const { firstName, lastName, email } = userData;

  const response = await http.post('/api/alltid/passwordless', {
    email,
    lastname: lastName,
    name: firstName,
    newsletterSubscription,
  });

  const { message } = response?.data || {};
  const { cc: alltIdUserCode, isNewUser } = message || {};

  if (!alltIdUserCode) {
    throw new Error('Could not create passwordless account');
  }

  return { alltIdUserCode, isNewUser };
};
